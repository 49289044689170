* {
  // background: whitesmoke;
  color: black;
  text-decoration: none;
  user-select: none;
  &:focus {
    outline: none;
  }
}

.navigation {
  display: flex;
  width: 100%;
  // padding: 10px 0px;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  .link {
    border-bottom: 2px solid transparent;
    width: 15%;
    background: darken(#fff, 10%);
    font-size: 1rem;
    padding: 0 10px;
    // border-radius: 10px;
    font-weight: bold;
    text-align: center;
    align-items: center;
    p {
      background: transparent;
      color: grey;
    }
    &:hover {
      border-bottom: 2px solid cyan;
      background: #333;
      p {
        color: white;
      }
    }
  }
}
.link3 {
  margin: 10px auto;
  width: 50%;
  background: darken(#fff, 10%);
  font-size: 1rem;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.2);
  p {
    width: 100%;
    background: transparent;
    color: grey;
  }
  &:hover {
    background: #333;
    p {
      color: white;
    }
  }
}

.home {
  height: 80vh;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  .image {
    width: 50%;
    img {
      box-shadow: 8px 9px 10px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      width: 100%;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
    width: 50%;
  }
}

.about {
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
  justify-content: space-evenly;
  text-align: center;
  .bio {
    padding: 10px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  }
  .list{
    width:100%;
  }
  i.fab {
    width: 30%;
    padding:2rem;
    text-align: center;
    font-size: 4rem;
  }
  .fa-html5{
    color:#e44d26;
  }
  .fa-css3{
    color:#264de4;
  }
  .fa-js-square{
    color: #f0db4f;
  }
  .fa-react{
    color:skyblue;
  }
  .fa-node-js{
    color: #3C873A;
  }
}
.contact-form {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  height: 80vh;
  text-align: center;
  .divider{
    color:grey;
    margin:10px auto 25px;
    font-size: 1.2rem;
    width:100%;
  }
  h3 {
    padding: 20px 0;
    color: grey;
    font-size: 1.5rem;
    font-weight: bold;
  }
  a {
    border-radius: 10px;
    margin: 0 10px;
    i.fab {
      font-size: 4rem;
    }
    .fa-facebook {
      color: #3c5a99;
    }
    .fa-linkedin {
      color: #0e76a8;
    }
  }
}
.contact-me {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-content: center;
  color: grey;
  input {
    border: 1px solid lightgrey;
    padding: 10px;
    color: grey;
    font-size: 1.6rem;
    width: 80%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  textarea {
    text-decoration: underline;
    border: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding: 10px;
    color: grey;
    font-size: 1.6rem;
    width: 80%;
    height: 450px;
    resize: vertical;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  a {
    font-weight: bold;
    margin: 10px auto;
    border-radius: 10px;
    color: grey;
    font-size: 1.3rem;
    // border: 1px solid blue;
    text-align: center;
    padding: 1rem;
    width: 20%;
    &:hover {
      background: #333;
      color: white;
    }
  }
}
